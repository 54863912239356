import React from 'react' // Estos archivos se crean primero para tener un side y un nav bar
import {NavLink, useNavigate} from 'react-router-dom' // useNavigate se agrega al final
import { IoPerson, IoPricetag, IoHome, IoLogOut } from "react-icons/io5"
import { BsBuilding } from "react-icons/bs"
import { MdHowToVote } from "react-icons/md"
import { GiArchiveRegister } from "react-icons/gi"
import { useDispatch, useSelector } from "react-redux"; // Se agrega casi al final
import { LogOut, reset } from "../features/authSlice" // Se agrega casi al final

export const Sidebar = () => {
  const dispatch = useDispatch(); // Se agrega casi al final
  const navigate = useNavigate(); // Se agrega casi al final
  const {user} = useSelector((state) => state.auth); // Se agrega casi al final

  const logout = () => {
    dispatch(LogOut(user.id));
    dispatch(reset());
    navigate("/")
  }

  return (
    <div className='is-hidden-mobile'>
        <aside className="menu pl-2 has-shadow">
        <p className="menu-label">
            General
        </p>
        <ul className="menu-list">
            <li><NavLink to={"/dashboard"}><IoHome className='mr-2'/>Inicio</NavLink></li>
            <li><NavLink to={"/registro-votos"}><IoPricetag className='mr-2'/>Registro de votaciones</NavLink></li>
        </ul>
        {user && user.role === "admin" && (
            <div>
                <p className="menu-label">
                    Admin
                </p>
                <ul className="menu-list">
                    <li><NavLink to={"/users"}><IoPerson className='mr-2'/>Usuarios</NavLink></li>
                    <li><NavLink to={"/empresas"}><BsBuilding className='mr-2'/>Empresas</NavLink></li>
                    <li><NavLink to={"/votaciones"}><MdHowToVote className='mr-2'/>Votaciones</NavLink></li>
                    <li><NavLink to={'/pre-registro'}><GiArchiveRegister className='mr-2'/>Pre registros</NavLink></li>
                </ul>
            </div>
        )}        
        <p className="menu-label">
            Settings
        </p>
        <ul className="menu-list">
            <li><button onClick={logout} className='button -is-white mr-2'><IoLogOut/>Logout</button></li>
        </ul>
        </aside>
    </div>
  )
}

export default Sidebar
