import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { alert, confirmAlert } from "../js/alert.js";
import Swal from "sweetalert2";

function FormAddEmpresa() {

    const [nombre_empresas, setNombre_empresas] = useState("");
    const [rfc_empresa, setRfc_empresa] = useState("");
    const [loading, setLoading] = useState(false);
    //const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    /* const saveCompany = async (e) => {
        e.preventDefault();
        try {
            await axios.post("https://api-v1.seccion15.org.mx/empresas", {
                nombre_empresas,
                rfc_empresa
            });
            alert({
                title: "Genial !!",
                msg: "Registro exitoso",
                icon: "success"
            });
            navigate("/empresas");
        } catch (error) {
            if(error.response){
                //setMsg(error.response.data.msg)
                alert({
                    title: "Error !!",
                    msg: error.response.data.msg,
                    icon: "error",
                });
            }
        }
    } */

    const confirmAlert = (e) => {
        e.preventDefault();
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas agregar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
          })
            .then(async(response) => {
              if (response.isConfirmed) {
                try {
                    document.getElementById("body-add-empresa").style.display = "none";
                    setLoading(true);
                    await axios.post("https://api-v1.seccion15.org.mx/empresas", {
                        nombre_empresas,
                        rfc_empresa
                    });
                    document.getElementById("body-add-empresa").style.display = "";
                    setLoading(false);
                    alert({
                        title: "Genial !!",
                        msg: "Registro exitoso",
                        icon: "success"
                    });
                    navigate("/empresas");
                } catch (error) {
                    document.getElementById("body-add-empresa").style.display = "";
                    setLoading(false);
                    if(error.response){
                        //setMsg(error.response.data.msg)
                        alert({
                            title: "Error !!",
                            msg: error.response.data.msg,
                            icon: "error",
                        });
                    }
                }
              } else if (response.isDenied) {
                document.getElementById("body-add-empresa").style.display = "";
                setLoading(false);
              }
            })
    }

    return (
        <div className="pr-2">
            <h1 className="title">Empresas</h1>            
            <h2 className="subtitle">Agregar una Empresa</h2>
            <div className="card id-shadowless" id="body-add-empresa">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={confirmAlert}>
                            <div className="field">
                                <label htmlFor="" className="label">Nombre de la Empresa</label>
                                <div className="control">
                                    <input type="text" value={nombre_empresas} onChange={(e) => setNombre_empresas(e.target.value)} className="input" placeholder="Nombre de la empresa"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">RFC de la Empresa</label>
                                <div className="control">
                                    <input type="text" value={rfc_empresa} onChange={(e) => setRfc_empresa(e.target.value)} className="input" placeholder="RFC de la Empresa"/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
    )
}

export default FormAddEmpresa