import React from "react";
import { useState } from "react";
import logo from "../logo.png";
import loading from "../loading.gif"
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { alert } from "../js/alert";
//import https from "https";

function ForgotPassword(){

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userEmail = {
            email: email
        };

        /*
        const agent = new https.Agent({ 
            keepAlive: true 
        });
        */

        setIsLoading(true);
        
        try {

            const response = await axios.post(`https://api-v1.seccion15.org.mx/resetPassword`, {
                email: userEmail
            });

            Swal.fire({
                title: "Genial !!",
                text: `${response.data.msg}`,
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#0086bf"
            })
            setIsLoading(false);
        } catch (error) {
            if(error.response){
                alert({
                    title: "Recuperación de contraseña",
                    msg: error.response.data.msg,
                    icon: "error" 
                });
            }
        }        

    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-5">

                            <form className="box" onSubmit={handleSubmit}>
                                <div className="card-image has-text-centered">
                                    <figure className="image is-128x128 is-inline-block">
                                        <img src={logo} width="112" height="28" alt='Logo' />
                                    </figure>
                                </div>
                                <div className="has-text-centered mb-2">
                                    <h1 className='title is-2'>Sistema Electrónico</h1>
                                    <h2>Recupera tu contraseña</h2>
                                </div>

                                <div className="field">
                                <label htmlFor="" className="label">Correo Electrónico:</label>
                                    <div className="control">
                                        <input
                                        type="email"
                                        className='input'
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder='correo@outlook.com' />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <Link to={'/'}>¿Ya tienes una cuenta?, inicia sesión</Link>
                                </div>

                                <div className="divButton">
                                    {isLoading
                                        ?
                                        <div className="loadingImage">
                                            <img src={loading} alt="loading" />
                                        </div>
                                        :
                                        <button type="submit" className="button is-danger is-fullwidth">Enviar</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default ForgotPassword;