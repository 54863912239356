import React from "react";
import notFound from "../404.jpg";

function NotFound() {
  return (
    <div className="container">
      <div className="columns is-mobile is-centered">
        <div className="column is-half">
          <img src={notFound} alt="not found" />
        </div>
      </div>
    </div>
  )
}

export default NotFound;