import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { alert } from "../js/alert";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import * as DOMPurify from "dompurify";
import moment from "moment";
import 'moment-timezone';

function Boleta() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();
    const {user} = useSelector((state) => state.auth);

    const [votacion_user, setVotacion] = useState([]);
    const [uuidValido, setUuidValidos] = useState([]);
    const [user_votacion, setUservotacion] = useState("");

    const [loading, setLoading] = useState(false);

    const [voto, setVoto] = useState("");
    const confirmAlert = (e) => {
        e.preventDefault();
        
        var label = document.querySelector("#radio"+voto);
        var texto_respuesta = '';
        if(label){
            var texto_respuesta = label.innerText
        }                

        Swal.fire({
            title: "Registro de voto",            
            html: '¿Estás seguro de votar por esta opción <b>"'+`${texto_respuesta}`+'"</b>?<br/><br/><p><b>Para poder terminar el registro de tu voto debes ingresar tu token de seguridad, este token llego a tu correo electronico cuando te registraste</p></b>',
            input: 'text',
            inputPlaceholder: "Token de seguridad",
            icon: "question",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#0086BF"
        })
        .then(async(result) => {
            if(result.value){
                
                let token = result.value;
                let id_usuario = user.uuid;
                if(result.isConfirmed){
                    if(voto === "" || voto == null){
                        alert({
                            title: "¡Error!",
                            msg: "Debes seleccionar una opción de la votación",
                            icon: "error"
                        });
                        return;
                    }
    
                    try {
                        document.getElementById("body-boleta-view").style.display = "none";
                        setLoading(true);
                        await axios.patch(`https://api-v1.seccion15.org.mx/respuestas/${id_votacion}`, {
                            voto,
                            token,
                            id_votacion,
                            id_usuario,
                            texto_respuesta
                        });
                        document.getElementById("body-boleta-view").style.display = "";
                        setLoading(false);
                        alert({
                            title: "¡Voto registrado!",
                            msg: "Tu voto se registro correctamente",
                            icon: "success"
                        });
                        navigate("/");
                    } catch (error) {
                        if(error){
                            document.getElementById("body-boleta-view").style.display = "";
                            setLoading(false);
                            alert({
                                title: "¡Error!",
                                msg: error.response.data.msg,
                                icon: "error"
                            });
                        }
                    }
    
                }

            }else{
                alert({
                    title: "¡Error!",
                    msg: "Debes escribir tu token de seguridad para poder votar",
                    icon: "error"
                });
                return false
            }
        })
    }

    const getVotacion = async() => {

        document.getElementById("body-boleta-view").style.display = "none";
        setLoading(true);

        const response = await axios.get(`https://api-v1.seccion15.org.mx/votacion/${id}`);        

        const dataVotaciones = response.data;

        document.getElementById("body-boleta-view").style.display = "";
        setLoading(false);

        var MyDate = new Date();
        var MyDateString;
        MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);

        const zonaHorariaUsuario = moment.tz.guess();        
        moment.tz.setDefault(zonaHorariaUsuario);
        var horaUsuario = moment();
        var horaRealUsuario = horaUsuario.format('HH:mm:ss');

        moment.tz.setDefault('America/Mexico_City');
        var horaMexico = moment();
        var horaRealMexico = horaMexico.format('HH:mm:ss');

        if(horaRealUsuario < horaRealMexico){
            horaUsuario.add(1, 'hours');
            horaRealUsuario = horaUsuario.format('HH:mm:ss');
        }else if(horaRealUsuario > horaRealMexico){
            horaUsuario.subtract(1, 'hours');
            horaRealUsuario = horaUsuario.format('HH:mm:ss');
        }

        // Validamos que este dentro de la fecha y hora de inicio
        if(MyDateString >= dataVotaciones.fecha_inicio){
            
            if(MyDateString == dataVotaciones.fecha_inicio && horaRealUsuario < dataVotaciones.hora_inicio){
                Swal.fire({
                    title: "Alerta!!",
                    text: "La votación aún no esta disponible",
                    icon: "warning",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "De acuerdo",
                    confirmButtonColor: "#0086bf"
                })
                .then((response) => {
                    if (response.isConfirmed) {
                        navigate("/")
                    }
                })
            }else{
    
            }

            /*
            if(horaRealUsuario >= dataVotaciones.hora_inicio){

            }else{
                Swal.fire({
                    title: "Alerta!!",
                    text: "La votación aún no esta disponible",
                    icon: "warning",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "De acuerdo",
                    confirmButtonColor: "#0086bf"
                })
                .then((response) => {
                    if (response.isConfirmed) {
                        navigate("/")
                    }
                })
            }
            */

        }else{
            Swal.fire({
                title: "Alerta!!",
                text: "La votación aún no esta disponible",
                icon: "warning",
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: "De acuerdo",
                confirmButtonColor: "#0086bf"
            })
            .then((response) => {
                if (response.isConfirmed) {
                    navigate("/")
                }
            })
        }

        // Validamos que estemos dentro de la fecha de termino
        if(MyDateString <= dataVotaciones.fecha_termino){
            if(MyDateString == dataVotaciones.fecha_termino && horaRealUsuario > dataVotaciones.hora_termino){
                Swal.fire({
                    title: "Alerta!!",
                    text: "La votación ya no esta disponible",
                    icon: "warning",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "De acuerdo",
                    confirmButtonColor: "#0086bf"
                })
                .then((response) => {
                    if (response.isConfirmed) {
                        navigate("/")
                    }
                })
            }else{
    
            }
        }else{
            Swal.fire({
                title: "Alerta!!",
                text: "La votación ya no esta disponible",
                icon: "warning",
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: "De acuerdo",
                confirmButtonColor: "#0086bf"
            })
            .then((response) => {
                if (response.isConfirmed) {
                    navigate("/")
                }
            })
        }
        
        for (const key in dataVotaciones) {
            if (dataVotaciones.hasOwnProperty.call(dataVotaciones, key)) {
                if(key !== 'respuestas_votaciones'){
                    const element = DOMPurify.sanitize(dataVotaciones[key]);
                    dataVotaciones[key] = element
                }
            }
        }

        if(dataVotaciones.respuestas_votaciones[0] !== undefined){
            let respuestas = dataVotaciones.respuestas_votaciones[0].respuestas;                
            for (const key in respuestas) {
                if (Object.hasOwnProperty.call(respuestas, key)) {
                    const element = respuestas[key];
                    const elementTotal = DOMPurify.sanitize(element.total);
                    const elementRespuesta = DOMPurify.sanitize(element.respuesta);
                    respuestas[key].total = elementTotal;
                    respuestas[key].total = elementRespuesta;
                }
            }
        }        

        response.data = dataVotaciones;

        setVotacion(response.data);        
    }    

    useEffect(() => {
        getVotacion();
        setUservotacion(votacion_user.uuid);
        if(user){
            const uuid_valido = []
            user.empresa.votacions.forEach(votacion => {
                uuid_valido.push(votacion.uuid);
            });
            setUuidValidos(uuid_valido);
        }

        if(votacion_user.uuid){
            if(!uuidValido.includes(votacion_user.uuid)){
                navigate("/")
            }
        }
        
    }, [user, dispatch, navigate]);        

    let respuestas;
    let i;
    const arr = [];
    let lista_x;
    if(votacion_user && votacion_user.respuestas_votaciones){
        
        if(votacion_user.respuestas_votaciones[0]){
            respuestas = votacion_user.respuestas_votaciones[0].respuestas;
            for(i in respuestas){
                arr.push(respuestas);
            }
        }

    }


    let id_votacion;
    if(votacion_user){
        id_votacion = votacion_user.id;
    }
    
    if(respuestas){
        lista_x = arr.map((respuesta, index) => (
            <label key={index} className="radio mb-4 ml-0 is-size-4" style={{"display": "block"}}>
                <input type="radio" className="mr-2" key={index} value={index} onChange={(e) => setVoto(e.target.value)} name="voto"/>
                <span id={`radio${index}`}>{respuesta[index].respuesta}</span>
            </label>
        ))
    }else{
        lista_x = <p className="is-size-6"><b>Sin respuestas registradas para esta encuesta</b></p>
    }    

    //const Answer_list = Respuestar_list(arr);

    return (
        <section className="hero has-background-grey-lighter is-fullheight is-fullwidht">
            <div className="hero-body" id="body-boleta-view">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-10 box shadow has-text-centered">
                            <h2 className="is-size-2"><b>{votacion_user && votacion_user.titulo}</b></h2>
                            <p className="is-size-5">{votacion_user && votacion_user.descripcion}</p>                            
                            <p className="is-size-3"><b>{votacion_user && votacion_user.instrucciones}</b></p>
                            <p className="is-size-5"><b>{votacion_user && votacion_user.observaciones}</b></p>
                            <br/>
                            {/*<p className="is-size-5">{user && user.empresa.votacions.lugar}</p>*/}
                            <p className="is-size-5">Fecha de inicio: <b>{votacion_user && votacion_user.fecha_inicio}</b> - Fecha de término: <b>{votacion_user && votacion_user.fecha_termino}</b></p>                            
                            <p className="is-size-5">Hora de inicio: <b>{votacion_user && votacion_user.hora_inicio}</b> - Hora de término: <b>{votacion_user && votacion_user.hora_termino}</b></p>
                            <p className="is-size-5"><b>Horas del centro de México</b></p>
                            {/*<p className="is-size-5">Empresa: <b>user && user.empresa.nombre_empresas</b></p>*/}
                            <br/>
                            <p className="is-size-2"><b>{votacion_user && votacion_user.pregunta}</b></p>
                            <br/>
                            <form onSubmit={confirmAlert}>
                                <div className="columns is-centered">
                                    <div className="control column has-text-left is-4">
                                        {lista_x}
                                    </div>
                                </div>
                                <div>                                    
                                    <Link to={'/'} className="button is-danger is-large is-focused">Cancelar</Link>
                                    <button type="submit" className="button is-success is-large is-focused ml-2">Votar</button>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </section>
    )

}

export default Boleta