import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { alert } from "../js/alert.js";
import Swal from "sweetalert2";
import * as DOMPurify from "dompurify";
import "../css/spinner.css";

function VotacionesList() {
    const [votaciones, setVotaciones] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getVotaciones();
    }, []);

    const getVotaciones = async () => {

        document.getElementById("body").style.display = "none";
        setLoading(true);
        
        const response = await axios.get("https://api-v1.seccion15.org.mx/votacion");

        for (let index = 0; index < response.data.length; index++) {
            const votaciones = response.data[index];
            let empresas = votaciones['empresa'];
            
            for (const key in votaciones) {
                if (votaciones.hasOwnProperty.call(votaciones, key)) {                    
                    if(key !== 'empresa'){
                        response.data[index][key] = DOMPurify.sanitize(votaciones[key]);
                    }else{
                        for (const key in empresas) {
                            if (empresas.hasOwnProperty.call(empresas, key)) {
                                const element = empresas[key];
                                empresas[key] = DOMPurify.sanitize(element);
                            }
                        }
                        response.data[index]['empresa'] = empresas;
                    }
                }
            }
            
        }

        document.getElementById("body").style.display = "";
        setLoading(false);

        setVotaciones(response.data);
    }

    const deleteVotacion = async (votacionID) => {
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas eliminar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
        })
            .then(async (response) => {
                if (response.isConfirmed) {
                    document.getElementById("body").style.display = "none";
                    setLoading(true);
                    await axios.delete(`https://api-v1.seccion15.org.mx/votacion/${votacionID}`);
                    alert({
                        title: "Genial !!",
                        msg: "Registro eliminado correctamente",
                        icon: "success"
                    })
                    document.getElementById("body").style.display = "";
                    setLoading(false);
                    getVotaciones();
                } else if (response.isDenied) {
                    document.getElementById("body").style.display = "";
                    setLoading(false);
                }
            })
    }

    return (
        <>
        <div className="pr-2">
            <h1 className="title">Votaciones</h1>
            <h2 className="subtitle">Lista de votaciones registradas</h2>
            <Link to="/votaciones/add" className="button is-primary mb-2">Agregar una nueva votación</Link>
            <table className="table is-striped is-fullwidth" id="body">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Titulo</th>
                        <th>Descripción</th>
                        <th>Pregunta</th>
                        <th>Lugar</th>
                        <th>Fecha de inicio</th>
                        <th>Hora de inicio</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {votaciones.map((votacion, index) => (
                        <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{votacion.titulo}</td>
                            <td>{votacion.descripcion}</td>
                            <td>{votacion.pregunta}</td>
                            <td>{votacion.lugar}</td>
                            <td>{votacion.fecha_inicio}</td>
                            <td>{votacion.hora_inicio}</td>
                            <td>
                                <div>
                                    <Link to={`/votaciones/edit/${votacion.uuid}`} style={{display: "block"}} className="button is-small is-info ml-2 mb-2 is-fullwidth">Editar</Link>
                                    <button onClick={() => deleteVotacion(votacion.uuid)} style={{display: "block"}} className="button is-small is-danger ml-2 mb-2 is-fullwidth">Eliminar</button>                                    
                                    <Link to={`/votaciones/resultados/${votacion.uuid}`} style={{display: "block"}} className="button is-small is-success ml-2 mb-2 is-fullwidth">Ver resultados</Link>
                                </div>                                
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
        </>
    )
}

export default VotacionesList   