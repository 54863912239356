import React from "react"; // Este archivo se configura primero para poder tener un side un nav bar
import {NavLink, useNavigate} from "react-router-dom" // Se agrega casi al final el useNavigate
import { useDispatch, useSelector } from "react-redux"; // Se agrega casi al final
import { LogOut, reset } from "../features/authSlice" // Se agrega casi al final
import { IoPerson, IoPricetag, IoHome, IoLogOut } from "react-icons/io5"
import { BsBuilding } from "react-icons/bs"
import { MdHowToVote } from "react-icons/md"
import { GiArchiveRegister } from "react-icons/gi"
//import logo from "../logo.png"
import banner from "../banner113x29.png"

export const Navbar = () => {
  const dispatch = useDispatch(); // Se agrega casi al final
  const navigate = useNavigate(); // Se agrega casi al final
  const {user} = useSelector((state) => state.auth); // Se agrega casi al final

  const logout = () => {
    dispatch(LogOut(user.id));
    dispatch(reset());
    navigate("/")
  }

  const [isActive, setIsActive] = React.useState(false)

  return (
    <div> 
      <nav className="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <NavLink to="/dashboard" className="navbar-item">
            <img src={banner} width="112" height="28" alt="Logo"/>
          </NavLink>
          <a
            onClick={() => {
              setIsActive(!isActive);
            }}
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarBasicExample" className={`navbar-menu ${isActive ? "is-active" : ""} is-hidden-desktop`}>
          <div className="navbar-start">
          <p className="menu-label">
                General
            </p>
            <ul className="menu-list">
                <li><NavLink to={"/dashboard"}><IoHome className='mr-2'/>Inicio</NavLink></li>
                <li><NavLink to={"/registro-votos"}><IoPricetag className='mr-2'/>Registro de votaciones</NavLink></li>
            </ul>
            {user && user.role === "admin" && (
                <div>
                    <p className="menu-label">
                        Admin
                    </p>
                    <ul className="menu-list">
                        <li><NavLink to={"/users"}><IoPerson className='mr-2'/>Usuarios</NavLink></li>
                        <li><NavLink to={"/empresas"}><BsBuilding className='mr-2'/>Empresas</NavLink></li>
                        <li><NavLink to={"/votaciones"}><MdHowToVote className='mr-2'/>Votaciones</NavLink></li>
                        <li><NavLink to={'/pre-registro'}><GiArchiveRegister className='mr-2'/>Pre registros</NavLink></li>
                    </ul>
                </div>
            )}        
            <p className="menu-label">
                Settings
            </p>
            <ul className="menu-list">
                <li><button onClick={logout} className='button -is-white mr-2'><IoLogOut/>Logout</button></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar