import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRec, faceRecognition } from "../js/rec";
import { LogOut, reset } from "../features/authSlice";
import Swal from "sweetalert2";

function Layout( {children} ) {

    /* const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [devices, setDevices] = useState([]);
    const handleDevices = useCallback(
        mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")), [setDevices]
    );

    const logout = () => {
        dispatch(LogOut(user.id));
        dispatch(reset());
        navigate("/")
    } */

    /* useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices)
        if (user) {
            faceRecognition(user.id)
                .then((data) => {
                    if (!data) {
                        Swal.fire({
                            title: "Alerta!!",
                            text: "No tienes una foto registrada aún",
                            icon: "warning",
                            showDenyButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: "Tomar foto",
                            confirmButtonColor: "#0086bf",
                            denyButtonText: "Salir",
                            denyButtonColor: "#b00000"
                        })
                            .then(async (response) => {
                                if (response.isConfirmed) {
                                    navigate("/reconocimiento", { state: { id: user.id, cameras: devices.length } });
                                } else if(response.isDenied){
                                    logout();
                                }
                            })
                    } else {
                        getRec(user.uuid)
                            .then((response) => {
                                if (response === 0) {
                                    Swal.fire({
                                        title: "Alerta!!",
                                        text: "Ahora haremos el reconocimiento facial para validar que eres tú",
                                        icon: "question",
                                        showDenyButton: true,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        confirmButtonText: "De acuerdo",
                                        confirmButtonColor: "#0086bf",
                                        denyButtonText: "Salir",
                                        denyButtonColor: "#b00000"
                                    })
                                        .then((response) => {
                                            if (response.isConfirmed) {
                                                //console.log(devices);
                                                navigate("/reconocimiento-facial", { state: { id: user.id, cameras: devices.length } });
                                            } else if(response.isDenied){
                                                logout();
                                            }
                                        })
                                }
                            })

                    }
                })
        }
    }, [user, navigate, devices.length, handleDevices]) */

    return(
        <React.Fragment>
            <Navbar/>
            <div className="columns mt-6" style={{ minHeight: "100vh" }}>
                <div className="column is-2"><Sidebar/></div>
                <div className="column has-background-white-bis">
                    <main>{children}</main>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Layout