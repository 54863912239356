import React from "react";
import ForgotPassword from "../components/ForgotPassword";

function RecoverPassword(){

    return (
        <ForgotPassword/>
    )

}

export default RecoverPassword