import Swal from "sweetalert2";

export const alert = (data) => {
  Swal.fire({
    title: data.title,
    text: data.msg,
    icon: data.icon,
    width: 300
  })
}

export const alertDelete = (uuid, deleteUser, getUsers) => {
  Swal.fire({
    title: "Alerta!!",
    text: "¿Estás seguro que deseas eliminar este registro?",
    icon: "warning",
    showDenyButton: true,
    denyButtonText: "Cancelar",
    denyButtonColor: "#fdcb00",
    confirmButtonText: "Aceptar",
    confirmButtonColor: "#fd0000"
  })
    .then((response) => {
      if (response.isConfirmed) {
        deleteUser(uuid);
        alert({
          title: "Genial !",
          msg: "Registro eliminado correctamente",
          icon: "success"
        });
      } else if (response.isDenied) {
        getUsers();
      }
    })
}

export const confirmAlert = (e, action) => {
  Swal.fire({
    title: "Alerta!!",
    text: "¿Estás seguro que deseas eliminar este registro?",
    icon: "warning",
    showDenyButton: true,
    denyButtonText: "Cancelar",
    denyButtonColor: "#b82b28",
    confirmButtonText: "Aceptar",
    confirmButtonColor: "#0086bf"
  })
    .then((response) => {
      if (response.isConfirmed) {
        action(e);
        /* alert({
          title: "Genial !",
          msg: "Registro agregado correctamente",
          icon: "success"
        }); */
      } else if (response.isDenied) {
        /* getter(); */
      }
    })
}