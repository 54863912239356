import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import "../css/spinner.css";

function RegistroUsuerioVoto(){
    const {user} = useSelector((state) => state.auth);
    const [registroVotos, setRegistroVotos] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(user){
            getRegistroUsuariosVoto();
        }
    }, [user]);

    const getRegistroUsuariosVoto = async () => {
        document.getElementById("body-voto-list").style.display = "none";
        setLoading(true);
        const response = await axios.get(`https://api-v1.seccion15.org.mx/user_voto/${user.uuid}`);

        const data = response.data;

        for (let index = 0; index < data.length; index++) {
            const votaciones = data[index];
            
            for (const key in votaciones) {
                if (votaciones.hasOwnProperty.call(votaciones, key)) {
                    if(key !== 'usuario' && key !== 'votacion'){
                        response.data[index][key] = DOMPurify.sanitize(votaciones[key]);
                    }
                }
            }

            if(data[index]['usuario'] !== undefined){
                let usuarios = data[index]['usuario'];
                for (const key in usuarios) {
                    if (usuarios.hasOwnProperty.call(usuarios, key)) {                        
                        data[index]['usuario'][key] = DOMPurify.sanitize(usuarios[key]);
                    }
                }
            }

            if(data[index]['votacion'] !== undefined){
                let votacion = data[index]['votacion'];
                for (const key in votacion) {
                    if (votacion.hasOwnProperty.call(votacion, key)) {                        
                        data[index]['votacion'][key] = DOMPurify.sanitize(votacion[key]);
                    }
                }
            }

        }

        document.getElementById("body-voto-list").style.display = "";
        setLoading(false);
        setRegistroVotos(response.data);
    }

    return (
        <>
            <div className="pr-2" id="body-registro-votos">
                <h1 className="title">Registro de votos emitidos</h1>
                <h2 className="subtitle">Lista de votos emitidos</h2>
                <table className="table is-striped is-fullwidth" id="body-voto-list">
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>Votación</td>
                            <td>Fecha</td>
                            <td>Hora inicio - Hora termino</td>
                            <td>Acciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        {registroVotos.map((votos, index) => (
                            <tr key={votos.uuid}>
                                <td>{index + 1}</td>
                                <td>{votos.votacion.titulo}</td>
                                <td>{votos.votacion.fecha_inicio}</td>
                                <td>{votos.votacion.hora_inicio} - {votos.votacion.hora_termino}</td>
                                <td>
                                <Link to={`/consulta/${votos.uuid}`} className="button is-small is-info">Consultar</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
            </div>
        </>
    )

}

export default RegistroUsuerioVoto