import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as DOMPurify from "dompurify";
import axios from "axios"; // Se agrega casi al final

const initialState = { // Se agrega casi al final
    user: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    photo: false
}

export const LoginUser = createAsyncThunk("user/LoginUser", async(user, thunkAPI) => {
    try {
        const response = await axios.post('https://api-v1.seccion15.org.mx/login', {                                           
            email: user.email,
            password: user.password
        },{withCredentials: true})
        
        for (const key in response.data) {
            if (response.data.hasOwnProperty.call(response.data, key)) {                
                const element = DOMPurify.sanitize(response.data[key]);
                response.data[key] = element;
                
            }
        }

        return response.data;
    } catch (error) {
        if(error.response){
            const message = error.response.data.msg
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const getMe = createAsyncThunk("user/getMe", async(_, thunkAPLI) => {
    try {
        const response = await axios.get('https://api-v1.seccion15.org.mx/Me',{withCredentials: true})
        const data = response.data
        for (const key in data) {
            if (data.hasOwnProperty.call(data, key)) {
                if(key !== 'empresa'){
                    const element = DOMPurify.sanitize(data[key]);
                    data[key] = element;
                }                
            }
        }

        response.data = data;
        //votacions

        const dataEmpresa = response.data.empresa;
        for (const key in dataEmpresa) {
            if (dataEmpresa.hasOwnProperty.call(dataEmpresa, key)) {
                if(key !== 'votacions'){
                    const element = DOMPurify.sanitize(dataEmpresa[key]);
                    dataEmpresa[key] = element
                }                
            }
        }

        response.data.empresa = dataEmpresa

        const dataVotaciones = response.data.empresa.votacions;
        let jsonVotaciones;

        for (let index = 0; index < dataVotaciones.length; index++) {
            jsonVotaciones = dataVotaciones[index];
            for (const key in jsonVotaciones) {
                if (jsonVotaciones.hasOwnProperty.call(jsonVotaciones, key)) {
                    if(key !== 'respuestas_votaciones'){
                        const element = DOMPurify.sanitize(jsonVotaciones[key]);
                        dataVotaciones[index][key] = element
                    }
                }
            }

            if(jsonVotaciones.respuestas_votaciones[0] !== undefined){
                let respuestas = jsonVotaciones.respuestas_votaciones[0].respuestas;                
                for (const key in respuestas) {
                    if (Object.hasOwnProperty.call(respuestas, key)) {
                        const element = respuestas[key];
                        const elementTotal = DOMPurify.sanitize(element.total);
                        const elementRespuesta = DOMPurify.sanitize(element.respuesta);
                        respuestas[key].total = elementTotal;
                        respuestas[key].respuesta = elementRespuesta;
                    }
                }
            }
        }
        
        response.data.empresa.votacions = dataVotaciones;
        
        return response.data;
    } catch (error) {
        
        if(error.response){
            const message = error.response.data.msg
            return thunkAPLI.rejectWithValue(message);
        }
    }
})

export const LogOut = createAsyncThunk("user/LogOut", async(id) => {
    await axios.post('https://api-v1.seccion15.org.mx/rec', {
        rec: 0,
        id: id
    });
    await axios.delete('https://api-v1.seccion15.org.mx/logOut')
})

export const authSlice = createSlice({ 
    name: "auth",
    initialState,
    reducers:{
        reset: (state) => initialState
    },
    extraReducers:(builder) => {
        builder.addCase(LoginUser.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(LoginUser.fulfilled, (state, action) => {
            state.isLoading = true;
            state.isSuccess = true;
            state.user = action.payload;
            state.photo = false;
        });
        builder.addCase(LoginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload
        })

        // Get UserLogin
        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = true;
            state.isSuccess = true;
            state.user = action.payload;
            state.photo = true;
        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload
        })
    }
});

export const {reset} = authSlice.actions;
export default authSlice.reducer;