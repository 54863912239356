import React, { useState, useEffect, useCallback } from "react"; // Se agrega casi al final solo el useEffect
import { useDispatch, useSelector } from "react-redux"; // Se agrega casi al final
import { useNavigate } from "react-router-dom"; // Se agrega casi al final
import { LoginUser, reset, LogOut } from "../features/authSlice" // Se agrega casi al final
import logo from "../logo.png"
import { Link } from "react-router-dom";
import { faceRecognition } from "../js/rec";
import Swal from "sweetalert2";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [devices, setDevices] = useState([]);
    const handleDevices = useCallback(
        mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")), [setDevices]
    );
    const { user, isError, isSuccess, isLoading, message, photo } = useSelector(
        (state) => state.auth
    );

    const logout = () => {
        dispatch(LogOut(user.id));
        dispatch(reset());
        navigate("/")
    }

    useEffect(() => {
        if (user || isSuccess) {
            navigator.mediaDevices.enumerateDevices().then(handleDevices)
            faceRecognition(user.id)
                .then((data) => {
                    if (!data) {
                        Swal.fire({
                            title: "Alerta!!",
                            text: "No tienes una foto registrada aún",
                            icon: "warning",
                            showDenyButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: "Tomar foto",
                            denyButtonText: "Salir",
                            confirmButtonColor: "#0086bf",
                            denyButtonColor: "#b00000"
                        })
                            .then(async (response) => {
                                if (response.isConfirmed) {
                                    navigate("/reconocimiento", { state: { id: user.id, cameras: devices.length } });
                                } else if (response.isDenied) {
                                    logout();
                                }
                            })
                    } else {
                        if (photo === false) {
                            Swal.fire({
                                title: "Alerta!!",
                                text: "Ahora haremos el reconocimiento facial para validar que eres tú",
                                icon: "question",
                                showDenyButton: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                confirmButtonText: "De acuerdo",
                                denyButtonText: "Salir",
                                confirmButtonColor: "#0086bf",
                                denyButtonColor: "#b00000"
                            })
                                .then((response) => {
                                    if (response.isConfirmed) {
                                        //console.log(devices);
                                        navigate("/reconocimiento-facial", { state: { id: user.id, cameras: devices.length } });
                                    } else if (response.isDenied) {
                                        setEmail("");
                                        setPassword("");
                                        logout();
                                    }
                                })
                        } else {
                            navigate("/dashboard");
                        }

                    }
                })

            /* navigate("/dashboard"); */
        } else {
            dispatch(reset());
        }
    }, [user, isSuccess, dispatch, navigate, photo, devices.length, handleDevices])

    const Auth = (e) => {
        e.preventDefault();
        dispatch(LoginUser({ email, password }));
    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4">

                            <form onSubmit={Auth} className='box'>
                                {isError &&
                                    <div className="notification is-danger is-light">
                                        <p className="has-text-centered">{message}</p>
                                    </div>
                                }
                                <div className="card-image has-text-centered">
                                    <figure className="image is-128x128 is-inline-block">
                                        <img src={logo} width="112" height="28" alt='Logo' />
                                    </figure>
                                </div>
                                <div className="has-text-centered mb-2">
                                    <h1 className='title is-2'>Sistema Electrónico</h1>
                                    <h2>Inicia sesión</h2>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="label">Email</label>
                                    <div className="control">
                                        <input
                                            type="text"
                                            className='input'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder='Email' />
                                    </div>
                                </div>

                                <div className="field">
                                    <label htmlFor="" className="label">Contraseña</label>
                                    <div className="control">
                                        <input
                                            type="password"
                                            className='input'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder='Contraseña' />
                                    </div>
                                </div>

                                <div className="field mt-5">
                                    <button
                                        type="submit"
                                        className="button is-danger is-fullwidth">
                                        {isLoading ? 'Loading...' : 'Login'}
                                    </button>
                                </div>
                                <div className="field mt-5">
                                    <Link to={'/registro-usuarios'} state={{ obj: "data", obj2: "data2" }}>Registrarse</Link>
                                </div>
                                <div className="field mt-5">
                                    <Link to={'/recuperar-cuenta'} state={{ obj: "data", obj2: "data2" }}>¿Olvidaste tu contraseña? Da clic aqui</Link>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login