import React, {useState} from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { alert } from "../js/alert.js";
import Swal from "sweetalert2";

function FormAddPreRegistro() {

    const [num_empleado, setNumEmpleado] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido_paterno, setApellidoP] = useState("");
    const [apellido_materno, setApellidoM] = useState("");
    const [rfc, setRFC] = useState("");
    const [curp, setCURP] = useState("");
    const [fecha_ingreso, setFechaIngreso] = useState("");
    const [posicion, setPosicion] = useState("");
    const [area, setArea] = useState("");
    const [rfc_empresa, setRfcEmpresa] = useState("");
    const [loading, setLoading] = useState(false)
    //const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    const savePreRegistro = async(e) => {
        e.preventDefault();
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas agregar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
          })
            .then(async(response) => {
              if (response.isConfirmed) {
                try {
                    document.getElementById("body-add-pre").style.display = "none";
                    setLoading(true);
                    await axios.post("https://api-v1.seccion15.org.mx/preRegistro", {
                        num_empleado: num_empleado,
                        nombre: nombre,
                        apellido_paterno: apellido_paterno,
                        apellido_materno: apellido_materno,
                        rfc: rfc,
                        curp: curp,
                        fecha_ingreso: fecha_ingreso,
                        posicion: posicion,
                        area: area,
                        rfc_empresa: rfc_empresa,                
                    });
                    document.getElementById("body-add-pre").style.display = "";
                    setLoading(false);
                    alert({
                        title: "Genial !!",
                        msg: "Registro exitoso",
                        icon: "success"
                    });
                    navigate("/pre-registro");
                } catch (error) {
                    document.getElementById("body-add-pre").style.display = "";
                    setLoading(false);
                    if(error.response){
                        //setMsg(error.response.data.msg);
                        alert({
                            title: "Error !!",
                            msg: error.response.data.msg,
                            icon: "error",
                        });
                    }
                }
              } else if (response.isDenied) {
                document.getElementById("body-add-pre").style.display = "";
                setLoading(false);
              }
            })
    }

    return (
        <div className="pr-2">
            <h1 className="title">Pre Registro</h1>
            <h2 className="subtitle">Agregar un pre registro de usuarios</h2>
            <div className="card id-shadowless" id="body-add-pre">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={savePreRegistro}>
                            {/* <p className="has-text-centered">{msg}</p> */}
                            <div className="field">
                                <label htmlFor="" className="label">Número de empleado</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={num_empleado} onChange={(e) => setNumEmpleado(e.target.value)}
                                    placeholder="Número de empleado"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Nombre</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={nombre} onChange={(e) => setNombre(e.target.value)}
                                    placeholder="Nombre"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Apellido paterno</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={apellido_paterno} onChange={(e) => setApellidoP(e.target.value)}
                                    placeholder="Apellido paterno"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Apellido materno</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={apellido_materno} onChange={(e) => setApellidoM(e.target.value)}
                                    placeholder="Apellido materno"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">RFC</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={rfc} onChange={(e) => setRFC(e.target.value)}
                                    placeholder="XXXXXXXXXXXXX"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">CURP</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={curp} onChange={(e) => setCURP(e.target.value)}
                                    placeholder="XXXXXXXXXXXXXXXXXX"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Fecha de ingreso</label>
                                <div className="control">
                                    <input type="date"
                                    value={fecha_ingreso} onChange={(e) => setFechaIngreso(e.target.value)}
                                    className="input"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Posición</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={posicion} onChange={(e) => setPosicion(e.target.value)}
                                    placeholder="Posición"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Area</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={area} onChange={(e) => setArea(e.target.value)}
                                    placeholder="Area"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">RFC de la Empresa</label>
                                <div className="control">
                                    <input type="text"
                                    className="input"
                                    value={rfc_empresa} onChange={(e) => setRfcEmpresa(e.target.value)}
                                    placeholder="RFC de la Empresa"/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">Registrar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
    )
}

export default FormAddPreRegistro