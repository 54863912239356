import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import axios from "axios";
import { alert } from "../js/alert.js";
import Swal from "sweetalert2";
import * as DOMPurify from "dompurify";

function PreRegistros() {
    const [preRegistros, setPreRegistros] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPreRegistros();
    }, []);

    const getPreRegistros = async () => {
        document.getElementById("body-lista-pre").style.display = "none";
        setLoading(true);
        const response = await axios.get("https://api-v1.seccion15.org.mx/preRegistro");
        for (let index = 0; index < response.data.length; index++) {
            const preRegistros = response.data[index];
            for (const key in preRegistros) {
                if (preRegistros.hasOwnProperty.call(preRegistros, key)) {
                    const element = DOMPurify.sanitize(preRegistros[key]);                    
                    response.data[index][key] = element;
                }
            }
        }      
        document.getElementById("body-lista-pre").style.display = "";
        setLoading(false);
        setPreRegistros(response.data);
    }

    const deletePreRegistro = async (preRegistroID) => {
        //console.log("Estamos en el delete");
        /* await axios.delete(`https://api-v1.seccion15.org.mx/preRegistro/${preRegistroID}`);
        getPreRegistros(); */
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas eliminar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
          })
            .then(async(response) => {
              if (response.isConfirmed) {
                document.getElementById("body-lista-pre").style.display = "none";
                setLoading(true);
                await axios.delete(`https://api-v1.seccion15.org.mx/preRegistro/${preRegistroID}`);
                alert({
                    title: "Genial !!",
                    msg: "Registro eliminado correctamente",
                    icon: "success"
                })
                document.getElementById("body-lista-pre").style.display = "";
                setLoading(false);
                getPreRegistros();
              } else if (response.isDenied) {
                document.getElementById("body-lista-pre").style.display = "";
                setLoading(false);
              }
            })
    }

    return (
        <div className="pr-2">
            <h1 className="title">Pre registros</h1>
            <h2 className="subtitle">Lista de Pre Registros de usuarios</h2>
            <Link to="/pre-registro/add" className="button is-primary mb-2">Agregar nuevo pre registro</Link>
            <Link to="/uploadCsv" className="button is-link ml-2">Subir CSV</Link>
            <table className="table is-striped is-fullwidth" id="body-lista-pre">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Num. Empleado</th>
                        <th>Nombre</th>
                        <th>A. paterno</th>
                        <th>A. materno</th>
                        <th>RFC</th>
                        <th>CURP</th>
                        <th>F. de ingreso</th>
                        <th>Posición</th>
                        <th>Area</th>
                        <th>RFC Empresa</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {preRegistros.map((preRegistro, index) => (
                        <tr key={preRegistro.uuid}>
                            <td>{index + 1}</td>
                            <td>{preRegistro.num_empleado}</td>
                            <td>{preRegistro.nombre}</td>
                            <td>{preRegistro.apellido_paterno}</td>
                            <td>{preRegistro.apellido_materno}</td>
                            <td>{preRegistro.rfc}</td>
                            <td>{preRegistro.curp}</td>
                            <td>{preRegistro.fecha_ingreso}</td>
                            <td>{preRegistro.posicion}</td>
                            <td>{preRegistro.area}</td>
                            <td>{preRegistro.rfc_empresa}</td>
                            <td>
                                <Link to={`/pre-registro/edit/${preRegistro.uuid}`} className="button is-small is-info">Editar</Link>
                                <button onClick={() => deletePreRegistro(preRegistro.uuid)} className="button is-small is-danger ml-2">Eliminar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
    )
}

export default PreRegistros