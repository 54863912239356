import axios from "axios";

export const getRec = async (id) => {
  let rec;
  try {
      const userRec = await axios.get(`https://api-v1.seccion15.org.mx/user/${id}`);
      rec = userRec.data;
  } catch (error) {
      if (error.response) {
          alert({
              title: "Error !!",
              msg: error.response.data.msg,
              icon: "error"
          });
      }
  }
  return rec.rec;
}

export const faceRecognition = async (id) => {
  let img;
  try {
      const imagen = await axios.get(`https://api-v1.seccion15.org.mx/imagenes/${id}`);
      img = imagen.data;
  } catch (error) {
      if (error.response) {
          alert({
              title: "Error !!",
              msg: error.response.data.msg,
              icon: "error"
          });
      }
  }
  return img;
}

//export default getRec;