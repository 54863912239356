import React, {useEffect} from "react"; // al final agregamos useEffect
import Layout from "./Layout";
import Welcome from "../components/Welcome";
import { useDispatch, useSelector } from "react-redux"; // Se agrega casi al final
import { useNavigate } from "react-router-dom"; // Se agrega casi al final
import { getMe } from "../features/authSlice"; // Se agrega casi al final

function Dashboard() {
    const dispatch = useDispatch(); // Se agrega casi al final
    const navigate = useNavigate(); // Se agrega casi al final
    const {isError, user} = useSelector((state => state.auth)); // Se agrega casi al final

    useEffect(() => { // Se agrega casi al final
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => { // Se agrega casi al final
        if(isError){
            navigate("/");
        }

        if(user && Object.entries(user).length === 0){
            navigate("/");
        }

        /* if(user && !user.role === 'admin'){
            navigate("/");
        }*/

        /** Ejemplo */
        
    }, [isError, navigate, user]);

    return (
        <Layout>
            <Welcome />
        </Layout>
    )
}

export default Dashboard