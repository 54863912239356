import React, {useEffect} from "react";
import Layout from "./Layout";
import RegistroVotosUsuarios from "../components/RegistroVotosList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";

function RegistroVotos(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isError, user} = useSelector((state => state.auth));

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if(isError){
            navigate("/")
        }
        if(!user){
            navigate("/dashboard")
        }
    }, [isError, user, navigate]);

    return (
        <Layout>
            <RegistroVotosUsuarios/>
        </Layout>
    )

}

export default RegistroVotos