import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "../css/spinner.css";

function Welcome() {

    const { user } = useSelector((state) => state.auth);
    const [votaciones, setVotaciones] = useState([]);
    const [loading, setLoading] = useState(true);
    let votaciones_validas = [];

    useEffect(() => {
        const timer = setTimeout(() => {
          setLoading(false);
          document.getElementById("body-welcome").style.display = "";
        }, 3000);
        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        if(user && user.empresa){
            setVotaciones(user.empresa.votacions);
        }
    }, [user]);

    if(votaciones){        

        let MyDate = new Date();
        let MyDateString;
        MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);

        let hora = MyDate.getHours()+':'+MyDate.getMinutes()+':'+MyDate.getSeconds();

        for(let i = 0; i < votaciones.length; i++){

            if(votaciones[i].is_activate == 1){

                let fecha_inicio = votaciones[i].fecha_inicio;
                let fecha_termino = votaciones[i].fecha_termino;

                let hora_inicio = votaciones[i].hora_inicio;
                let hora_termino = votaciones[i].hora_termino;

                let fechaActual = new Date();

                // Codigo de la IA
                if(MyDateString >= fecha_inicio && MyDateString <= fecha_termino){
                    votaciones_validas.push(votaciones[i]);
                }

                /*
                if(fecha_inicio >= MyDateString && MyDateString < fecha_termino){
                    if(hora >= hora_inicio && hora < hora_termino){
                        votaciones_validas.push(votaciones[i]);
                    }
                }

                if(MyDateString < fecha_termino){
                    if(hora > hora_termino){
                        votaciones_validas.push(votaciones[i]);

                        const index = votaciones.indexOf(i);
                        if (index > -1) { // only splice array when item is found
                            votaciones.splice(index, 1); // 2nd parameter means remove one item only
                        }

                    }
                }
                */

            }
            
        }

    }

    if(votaciones_validas && votaciones_validas.length > 0){

        return (
            <div>
                <h1 className="title">Votaciones activas</h1>
                <h2 className="subtitle">
                    Bienvenido <strong>{user && user.nombre}</strong>                
                </h2>
                <section>
                {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
                    <div id="body-welcome" style={{display: "none"}}>
                        {votaciones_validas.map((votacion, index) => (                        
                            <section key={votacion.uuid} className="hero has-background-white-bis is-fullwidth">
                                <div className="hero-body">
                                    <div className="container">
                                        <div className="columns is-centered">
                                            <div className="column is-8 box shadow has-text-centered">
                                                <h2 className="is-size-4"><b>{votaciones && votacion.titulo}</b></h2>
                                                <br />
                                                <p className="is-size-5">{votaciones && votacion.descripcion}</p>
                                                <br />
                                                <p className="is-size-5"><b>Dirección:</b></p>
                                                <p className="is-size-5">{votaciones && votacion.lugar}</p>
                                                <p className="is-size-5">Fecha de inicio: <b>{votaciones && votacion.fecha_inicio}</b> - Fecha de término: <b>{votaciones && votacion.fecha_termino}</b></p>                                                
                                                <p className="is-size-5">Hora de inicio: <b>{votaciones && votacion.hora_inicio}</b> - Hora de término: <b>{votaciones && votacion.hora_termino}</b></p>
                                                <p className="is-size-5"><b>Horas del centro de México</b></p>
                                                <p className="is-size-5">Empresa: <b>{user && user.empresa.nombre_empresas}</b></p>
                                                <br />
                                                <Link to={`${user && "/votacion/" + votacion.uuid}`} className="button is-primary is-danger is-focused">Participar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        ))}
                    </div>
                </section>
            </div>
        )

    }else{

        return (
            <div id="body-welcome" >
                <h1 className="title">Votaciones activas</h1>
                <h2 className="subtitle">
                    Bienvenido <strong>{user && user.nombre}</strong>                
                </h2>
                <section className="hero has-background-white-bis is-fullwidth">
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-centered">
                                <div className="column is-8 box shadow has-text-centered">
                                    <p className="is-size-2"><b>No hay ninguna votación activa</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Welcome