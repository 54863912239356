import React, {useState, useEffect} from "react"; // Se agrega casi al final el useState y el useEffect
import { Link } from "react-router-dom"; // se agrega casi al final
import axios from "axios"; // se agrega casi al final
import { alert } from "../js/alert";
import Swal from "sweetalert2";
import * as DOMPurify from "dompurify";

function UsersList() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        document.getElementById("body-user-list").style.display = "none";
        setLoading(true);
        const response = await axios.get("https://api-v1.seccion15.org.mx/users");            

        for (let index = 0; index < response.data.length; index++) {
            const usuarios = response.data[index];
            let empresas = usuarios['empresa'];            

            for (const key in usuarios) {
                if (usuarios.hasOwnProperty.call(usuarios, key)) {
                    if(key !== 'empresa'){
                        response.data[index][key] = DOMPurify.sanitize(usuarios[key]);
                    }else{
                        for (const key in empresas) {
                            if (empresas.hasOwnProperty.call(empresas, key)) {
                                const element = empresas[key];
                                empresas[key] = DOMPurify.sanitize(element);
                            }
                        }
                        response.data[index]['empresa'] = empresas;
                    }
                }
            }
        }

        document.getElementById("body-user-list").style.display = "";
        setLoading(false);

        setUsers(response.data);
    }

    const deleteUsers = async (userId) => {
        /* await axios.delete(`https://api-v1.seccion15.org.mx/users/${userId}`);
        getUsers(); */
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas eliminar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
          })
            .then(async(response) => {
              if (response.isConfirmed) {
                document.getElementById("body-user-list").style.display = "none";
                setLoading(true);
                await axios.delete(`https://api-v1.seccion15.org.mx/users/${userId}`);
                alert({
                    title: "Genial !!",
                    msg: "Registro eliminado correctamente",
                    icon: "success"
                })
                getUsers();
                document.getElementById("body-user-list").style.display = "";
                setLoading(false);
              } else if (response.isDenied) {
                getUsers();
                document.getElementById("body-user-list").style.display = "";
              }
            })
    }

    return (
        <div className="pr-2">
            <h1 className="title">Usuarios</h1>
            <h2 className="subtitle">Lista de usuarios</h2>
            <Link to="/users/add" className="button is-primary mb-2">Agregar nuevo usuario</Link>
            <table className="table is-striped is-fullwidth" id="body-user-list">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>No. Empleado</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Empresa</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.uuid}>
                            <td>{index + 1}</td>
                            <td>{user.num_empleado}</td>
                            <td>{user.nombre} {user.apellido_paterno} {user.apellido_materno}</td>
                            <td>{user.email}</td>
                            <td>{user.empresa.nombre_empresas}</td>
                            <td>
                                <Link to={`/users/edit/${user.uuid}`} className="button is-small is-info">Editar</Link>
                                <button onClick={() => deleteUsers(user.uuid)} className="button is-small is-danger ml-2">Eliminar</button>
                            </td>
                        </tr>
                    ))}                    
                </tbody>
            </table>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
    )
}

export default UsersList