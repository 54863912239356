import React, {useState, useEffect} from "react"; // Se agrega casi al final el useState y el useEffect
import { Link } from "react-router-dom"; // se agrega casi al final
import axios from "axios"; // se agrega casi al final
import { alert } from "../js/alert.js";
import Swal from "sweetalert2";
import * as DOMPurify from "dompurify";

function CompaniList() {

    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCompanies();
    }, []);

    const getCompanies = async () => {
        document.getElementById("body-com-list").style.display = "none";
        setLoading(true);
        const response = await axios.get("https://api-v1.seccion15.org.mx/empresas");
    
        for (let index = 0; index < response.data.length; index++) {
            const empresas = response.data[index];
            for (const key in empresas) {
                if (empresas.hasOwnProperty.call(empresas, key)) {
                    const element = DOMPurify.sanitize(empresas[key]);                    
                    response.data[index][key] = element;
                }
            }
        }     
        document.getElementById("body-com-list").style.display = "";
        setLoading(false);   
        setCompanies(response.data);
    }

    const deleteCompany = async (companyId) => {
        /* await axios.delete(`https://api-v1.seccion15.org.mx/empresas/${companyId}`);
        getCompanies(); */
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas eliminar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
          })
            .then(async(response) => {
              if (response.isConfirmed) {
                document.getElementById("body-com-list").style.display = "none";
                setLoading(true);
                await axios.delete(`https://api-v1.seccion15.org.mx/empresas/${companyId}`);
                document.getElementById("body-com-list").style.display = "";
                setLoading(false);   
                alert({
                    title: "Genial !!",
                    msg: "Registro eliminado correctamente",
                    icon: "success"
                })
                getCompanies();
              } else if (response.isDenied) {
                document.getElementById("body-com-list").style.display = "";
                setLoading(false);   
              }
            })
    }

    return (
        <div className="pr-2">
            <h1 className="title">Empresas</h1>
            <h2 className="subtitle">Lista de empresas</h2>
            <Link to="/empresas/add" className="button is-primary mb-2">Agregar nueva empresa</Link>
            <table className="table is-striped is-fullwidth" id="body-com-list">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>RFC</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {companies.map((company, index) => (
                        <tr key={ company.uuid }>
                            <td>{ index + 1 }</td>
                            <td>{ company.nombre_empresas }</td>
                            <td>{ company.rfc_empresa }</td>
                            <td>
                            <Link to={`/empresas/edit/${company.uuid}`} className="button is-small is-info">Editar</Link>
                                <button onClick={() => deleteCompany(company.uuid)} className="button is-small is-danger ml-2">Eliminar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
    )
}

export default CompaniList