import React, { useState } from "react";
import logo from "../logo.png";
import loading from "../loading.gif"
import { navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { alert } from "../js/alert";
import { useParams } from 'react-router-dom';

let regExPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,16}$/;

function ChangePass() {

    const { id, token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConf, setPasswordConf] = useState("");
    const [showPassword, setShowPassword] = useState(false);    

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!regExPassword.test(password)){
            alert({
                title: "Recuperación de contraseña",
                msg: "Tu contraseña debe contener minimo 8 caracteres, una letra mayuscula, un numero y un caracter especial",
                icon: "error" 
            });
            return;
        }

        if(password !== passwordConf){
            alert({
                title: "Recuperación de contraseña",
                msg: "Las contraseñas no coinciden",
                icon: "error" 
            });
            return;
        }
    
        setIsLoading(true);

        try {
            await axios.patch(`https://api-v1.seccion15.org.mx/resetPasswordUser/`+id+`/`+token, {
                password: password,
                passwordConfirm: passwordConf,
                id: id,
                tokenResetPassword: token
            })

            Swal.fire({
                title: "Genial !!",
                text: `Contraseña actualizada con éxito`,
                icon: "success",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#0086bf"
            })
            setIsLoading(false);
            navigate("/");
        } catch (error) {
            alert({
                title: "Recuperación de contraseña",
                msg: "Ha ocurrido un error",
                icon: "error" 
            });
            setIsLoading(false);
            return;
        }        

    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-5">

                            <form className="box" onSubmit={handleSubmit}>
                                <div className="card-image has-text-centered">
                                    <figure className="image is-128x128 is-inline-block">
                                        <img src={logo} width="112" height="28" alt='Logo' />
                                    </figure>
                                </div>
                                <div className="has-text-centered mb-2">
                                    <h1 className='title is-2'>Sistema Electrónico</h1>
                                    <h2>Recupera tu contraseña</h2>
                                </div>

                                <div className="field">
                                <label htmlFor="" className="label">Contraseña:</label>
                                    <div className="control">
                                        <input
                                        type="password"
                                        className='input'
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder='********' />
                                    </div>
                                </div>
                                <div className="field">
                                <label htmlFor="" className="label">Confirma tu Contraseña:</label>
                                    <div className="control">
                                        <input
                                        type="password"
                                        className='input'
                                        required
                                        value={passwordConf}
                                        onChange={(e) => setPasswordConf(e.target.value)}
                                        placeholder='********' />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <Link to={'/'}>¿Ya tienes una cuenta?, inicia sesión</Link>
                                </div>

                                <div className="divButton">
                                    {isLoading
                                        ?
                                        <div className="loadingImage">
                                            <img src={loading} alt="loading" />
                                        </div>
                                        :
                                        <button type="submit" className="button is-danger is-fullwidth">Actualizar</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default ChangePass;