import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import AuthReducer from "../features/authSlice"; // Se agrega casi al final 

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, AuthReducer);

export const store = configureStore({
  reducer: {
    auth: persistedReducer // Se agrega casi al final
  },
});
