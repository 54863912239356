import React, { useState} from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";

function UploadCsv(){

    // Recuperamos el archivo y su nombre
    const fileInput = document.querySelector('#file-csv input[type=file]');
    if(fileInput){
        fileInput.onchange = () => {
            const fileName = document.querySelector('#file-csv .file-name');
            fileName.textContent = fileInput.files[0].name
        }
    }

    const [archivo, setArchivos] = useState();
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const selectectFile = (event) => {
        setArchivos(event.target.files[0]);        
    }

    const insertarArchivo = async(e) => {
        e.preventDefault();        
        const formData = new FormData();

        // solo enviaremos un archivo, si necesitaramos dos, los recorremos con un for
        if(!archivo){
            setMsg("Debes Adjunta un archivo");
            return;
        }
        formData.append("preregistroscsv", archivo);        

        try {
            document.getElementById("file-csv").style.display = "none";
            setLoading(true);
            await axios.post("https://api-v1.seccion15.org.mx/uploadCSV", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            document.getElementById("file-csv").style.display = "";
            setLoading(false);
            //navigate("/pre-registro");
            setMsg("Informacion registrada con éxito");
        } catch (error) {
            document.getElementById("file-csv").style.display = "";
            setLoading(false);
            setMsg(error.response.data.msg);
            //console.log(error);
        }

    }    

    return (
        <div className="pr-2">
            <h1 className="title">Cargar pre registros desde CSV</h1>
            <h2 className="subtitle">Selecciona un archivo CSV para subir</h2>
            <p className="has-text-centered">{msg}</p>
            <div id="file-csv" className="file is-centered is-boxed is-success has-name">
                <form onSubmit={insertarArchivo} encType="multipart/form-data">                    
                    <br/>
                    <label className="file-label">
                    <input className="file-input" type="file" name="preregistroscsv" accept=".csv" onChange={selectectFile}/>
                        <span className="file-cta">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                                Selecciona un archivo
                            </span>
                        </span>
                        <span className="file-name">
                            Da clic para escoger un archivo
                        </span>
                    </label>                
                    <br/>
                    <div className="has-text-centered">
                        <button type="submit" className="button is-primary">Subir archivo</button>
                    </div>
                </form>
            </div>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}            
        </div>
    )
}

export default UploadCsv