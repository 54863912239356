import React, {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { alert } from "../js/alert";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

function ConsultaRegistroVoto(){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    const {user} = useSelector((state) => state.auth);
    const [registroVoto, setRegistroVoto] = useState("");

    const consultaVoto = () => {

        Swal.fire({
            title: "Consulta de voto",
            html: 'Para poder consultar la orientación de tu voto en esta votación, debes ingresar el token de seguridad que recibiste a tu correo',
            input: 'text',
            inputPlaceholder: "Token de seguridad",
            icon: "info",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#0086BF"
        })
        .then(async(result) => {
            if(result.value){
                let token = result.value;
                let id_registroVoto = registroVoto.uuid;
                if(result.isConfirmed){
                    try{
                        document.getElementById("body-regitro-voto").style.display = "none";
                        setLoading(true);
                        const response_voto = await axios.post(`https://api-v1.seccion15.org.mx/voto_registro/${id}`, {
                            token,
                            id_registroVoto
                        });
                        document.getElementById("body-regitro-voto").style.display = "";
                        setLoading(false);

                        Swal.fire({
                            title: '<strong>Consulta de Voto</strong>',
                            icon: 'info',
                            html: '<p> Tu voto registrado es: <br/><b>'+response_voto.data.msg+'</b></p>',
                            showCloseButton: true,
                            focusConfirm: false,
                            confirmButtonText:
                              '<i class="fa fa-thumbs-up"></i> Great!',
                            confirmButtonAriaLabel: 'Thumbs up, great!',
                            cancelButtonText:
                              '<i class="fa fa-thumbs-down"></i>',
                            cancelButtonAriaLabel: 'Thumbs down'
                        })

                    }catch (error){
                        if(error){
                            document.getElementById("body-regitro-voto").style.display = "";
                            setLoading(false);
                            alert({
                                title: "¡Error!",
                                msg: error.response.data.msg,
                                icon: "error"
                            });
                        }
                    }
                }
            }else{
                document.getElementById("body-regitro-voto").style.display = "";
                setLoading(false);
                alert({
                    title: "¡Error!",
                    msg: "Debes escribir tu token de seguridad para poder votar",
                    icon: "error"
                });
                return false
            }
        })

    }

    const getRegistroVoto = async() => {
        document.getElementById("body-regitro-voto").style.display = "none";
        setLoading(true);
        const response = await axios.get(`https://api-v1.seccion15.org.mx/consulta_voto/${id}`);
        document.getElementById("body-regitro-voto").style.display = "";
        setLoading(false)
        
        if(user.uuid !== response.data.usuario.uuid){
            return navigate("/");
        }
        
        setRegistroVoto(response.data);
    }

    useEffect(() => {
        if(user){
            getRegistroVoto();
        }
    }, [user, dispatch, navigate]);

    return (
        <section className="hero has-background-grey-lighter is-fullheight is-fullwidht">
            <div className="" id="body-regitro-voto">
                <div className="container">
                    <div className="columns is-centered mt-2">
                        <div className="column is-10 box shadow has-text-centered">
                            <h2 className="is-size-2"><b>{registroVoto && registroVoto.votacion.titulo}</b></h2>
                            <p className="is-size-5">{registroVoto && registroVoto.votacion.lugar}</p>
                            <br/>
                            <p className="is-size-5">Fecha de inicio: <b>{registroVoto && registroVoto.votacion.fecha_inicio}</b> - Fecha de término: <b>{registroVoto && registroVoto.votacion.fecha_termino}</b></p>
                            <br/>
                            <p className="is-size-5">Hora de inicio: <b>{registroVoto && registroVoto.votacion.hora_inicio}</b> - Hora de término: <b>{registroVoto && registroVoto.votacion.hora_termino}</b></p>
                            <br/>
                            <button type="submit" className="button is-success is-large is-focused ml-2" onClick={() => consultaVoto()} >Ver voto</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </section>
    )
}

export default ConsultaRegistroVoto