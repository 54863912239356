import React from "react";
import logo from "../logo.png"

function Registro() {    

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4">
                            <form className="box">
                                <div className="card-image has-text-centered">
                                    <figure className="image is-128x128 is-inline-block">
                                        <img src={logo} width="112" height="28" alt='Logo'/>
                                    </figure>
                                </div>
                                <div className="has-text-centered mb-2">
                                    <h1 className='title is-2'>Sistema Electronico</h1>
                                    <h2>Registro de nuevos usuarios</h2>
                                </div>                                                                
                                <div className="field">
                                    <label htmlFor="" className="label">No. Empleado</label>
                                    <div className="control">
                                        <input type="text" className='input' placeholder='Número de empleado'/>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="label">RFC del Trabajador</label>
                                    <div className="control">
                                        <input type="text" className='input' placeholder='RFC'/>
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-danger is-fullwidth">Continuar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Registro