import React, {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { alert } from "../js/alert";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

function FormRespuestasVotaciones(){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();
    const {user} = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    const [votacion, setVotacion] = useState([]);

    const getVotacion = async() => {
        document.getElementById("body-result-votacion").style.display = "none";
        setLoading(true);
        const response = await axios.get(`https://api-v1.seccion15.org.mx/votacion/${id}`);        
        setVotacion(response.data);        
        document.getElementById("body-result-votacion").style.display = "";
        setLoading(false);
    }

    useEffect(() => {
        getVotacion();
    }, [user, dispatch, navigate]);

    let respuestas;
    let i;
    const arr = [];
    let lista_x;
    if(votacion && votacion.respuestas_votaciones){
        respuestas = votacion.respuestas_votaciones[0].respuestas
        for(i in respuestas){
            arr.push(respuestas);
        }
    }

    if(respuestas){
        lista_x = arr.map((respuesta, index) => (
            <label key={index} className="mb-4 ml-0 is-size-4" style={{"display": "block"}}>
                <span><b>Respuesta:</b></span> {respuesta[index].respuesta} <span><b>Total:</b></span> {respuesta[index].total}
            </label>
        ))
    }else{
        lista_x = <p className="is-size-6"><b>Sin respuestas registradas para esta encuesta</b></p>
    }    

    return (
        <div>
            <h1 className="title">Conteo de votos</h1>
            <h2 className="subtitle">
                Bienvenido <strong>{user && user.nombre}</strong>
            </h2>
            <section>
                <div id="body-result-votacion">
                    <section key={votacion.uuid} className="hero has-background-white-bis is-fullwidth">
                        <div className="hero-body">
                            <div className="container">
                                <div className="columns is-centered">
                                    <div className="column is-8 box shadow has-text-centered">
                                        <h2 className="is-size-2"><b>{votacion && votacion.titulo}</b></h2>
                                        <br />
                                        <p className="is-size-5">{votacion && votacion.descripcion}</p>
                                        <br />
                                        <p className="is-size-5"><b>Dirección:</b></p>
                                        <p className="is-size-5">{votacion && votacion.lugar}</p>
                                        <p className="is-size-5">Fecha de inicio: <b>{votacion && votacion.fecha_inicio}</b> - Fecha de término: <b>{votacion && votacion.fecha_termino}</b></p>
                                        <p className="is-size-5">Hora de inicio: <b>{votacion && votacion.hora_inicio}</b> - Hora de término: <b>{votacion && votacion.hora_termino}</b></p>
                                        <p className="is-size-5">Empresa: <b>{user && user.empresa.nombre_empresas}</b></p>
                                        <div className="columns is-centered mt-2">
                                            <div className="control has-text-left is-4 ml-2 mt-2">
                                                {lista_x}
                                            </div>
                                        </div>
                                        <br />                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
            </section>
        </div>
    )

}

export default FormRespuestasVotaciones;