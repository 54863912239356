import React, { useEffect, useRef } from "react";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { alert } from "../js/alert.js";
import { useNavigate, useLocation } from "react-router-dom";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset, getMe } from "../features/authSlice";

function PeopleRegister() {

  const dispatch = useDispatch();
  const [picture, setPicture] = useState(null);
  const [image, setImage] = useState("");
  const [userData, setUserData] = useState();
  const imageDesc = useRef();
  const imageComp = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let webcamRef = React.useRef(null);
  const { isError, user } = useSelector((state) => state.auth);
  const location = useLocation();

  const logout = () => {
    dispatch(LogOut(user.id));
    dispatch(reset());
    navigate("/")
  }

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/")
    }
    if (!user) {
      navigate("/")
    }
  }, [isError, user, navigate]);

  useEffect(() => {
    if (location.state && location.state !== null && location.state.cameras <= 0) {
      Swal.fire({
        title: "Alerta!!",
        text: "Tu dispositivo no cuenta con una cámara que podamos utilizar para el reconocimiento facial, por favor, utiliza otro dispositivo",
        icon: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "De acuerdo",
        confirmButtonColor: "#0086bf"
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            logout();
          }
        })
    }

    const getImageById = async (id) => {
      try {
        const response = await axios.get(`https://api-v1.seccion15.org.mx/imagenes/${id}`);
        setUserData(response.data.id_usuario);
        setImage(response.data.url);
      } catch (error) {
        if (error) {
          alert({
            title: "Error !",
            msg: "Por favor póngase en contacto con el administrador",
            icon: "error"
          });
          navigate('/');
        }
      }
    }

    const loadModels = async () => {
      await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
      await faceapi.nets.faceExpressionNet.loadFromUri('/models');
    }
    loadModels();

    if(location.state && location.state.id !== null)
    getImageById(location.state.id)
  }, [navigate, location.state])

  const videoConstraints = {
    width: { min: 240 },
    height: { min: 360 },
    aspectRatio: 0.6666666667,
    facingMode: 'user'
  }

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc)
  }, [setPicture]);

  const loadImages = async () => {
    if (!picture) {
      alert({
        title: "Alerta !!",
        msg: "Necesitas tomar una fotografía",
        icon: "warning"
      })
    } else {
      Swal.fire({
        title: "Alerta!!",
        text: `Se hará el reconocimiento facial`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "Cancelar",
        denyButtonColor: "#b82b28",
        confirmButtonText: "Si, estoy de acuerdo",
        confirmButtonColor: "#0086bf"
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            getDescriptionTwo(imageComp.current)
              .then(async (res) => {
                if (!res || res === undefined) {
                  setLoading(false);
                  document.getElementById("special").style.display = "";
                  Swal.fire({
                    title: "Alerta !!",
                    text: `Esta fotografía no se ha podido tomar como referencia para el reconocimiento facial, por favor tome otra y siga las sugerencias`,
                    icon: "error",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#0086bf"
                  })
                    .then(async (response) => {
                      if (response.isConfirmed) {
                        setPicture(null);
                      }
                    })
                } else {
                  comparing(imageDesc.current, imageComp.current)
                    .then(async (res) => {
                      setLoading(false);
                      document.getElementById("special").style.display = "";
                      if (res > .70) {
                        Swal.fire({
                          title: "Alerta !!",
                          text: `Lo sentimos, no eres el dueño de esta cuenta `,
                          icon: "error",
                          allowOutsideClick: false,
                          allowEscapeKey: false,
                          confirmButtonText: "Ok",
                          confirmButtonColor: "#0086bf"
                        })
                          .then(async (response) => {
                            if (response.isConfirmed) {
                              setPicture(null);
                            }
                          })
                      } else {
                       /*  try {
                          await axios.post("https://api-v1.seccion15.org.mx/rec", {
                            rec: 1,
                            id: userData
                          }) */
                          alert({
                            title: "Genial !!",
                            msg: "Listo, ahora puedes navegar correctamente",
                            icon: "success"
                          });
                          navigate("/dashboard");
                        /* } catch (error) {
                          if (error) {
                            alert({
                              title: "Error !!",
                              msg: error.response.data.msg,
                              icon: "error"
                            });
                          }
                        } */
                      }
                    })
                }
              })
          } else if (response.isDenied) {

          }
        })
    }
  }

  const getDescriptionTwo = async (imgOne) => {
    setLoading(true);
    document.getElementById("special").style.display = "none";
    const comparativeImage = await faceapi.detectSingleFace(imgOne, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptor();
    return comparativeImage;
  }

  const comparing = async (imgOne, imgTwo) => {
    setLoading(true);
    document.getElementById("special").style.display = "none";
    const originalImage = await faceapi.detectSingleFace(imgOne, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptor();
    const comparativeImage = await faceapi.detectSingleFace(imgTwo, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptor();
    let distance;
    if (originalImage && comparativeImage) {
      distance = faceapi.euclideanDistance(originalImage.descriptor, comparativeImage.descriptor);
    }
    return distance;
  }

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body is-8" id="special">
        <div className="container">
          <div className="pr-2">
            <h1 className="title has-text-centered">Fotografía para reconocimiento facial</h1>
            <div className="card id-shadowless p-4">
              <article className="message is-warning">
                <div className="message-body">
                  <p className="is-size-4">Alguna de las sugerencias para tomar correctamente la foto son:</p>
                  <ul className="mt-2" style={{ "listStyleType": "circle" }}>
                    <li><b>Tomar la foto en un lugar con suficiente iluminación.</b></li>
                    <li><b>Tener el rostro lo más descubierto posible.</b></li>
                    <li><b>Que solo salga la persona responsable de la cuenta.</b></li>
                    <li><b>Lo mas de frente posible a la cámara.</b></li>
                  </ul>
                </div>
              </article>
              <div className="card-content">
                <div className="content">
                  <form>
                    <div className="field has-text-centered is-max-desktop">

                      {picture === null ? (
                        <>
                          <Webcam
                            width={240}
                            height={360}
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                          />
                          <br />
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              capture();
                            }}
                            className="button is-primary is-large"
                          >
                            Tomar foto
                          </button>
                        </>
                      ) : (
                        <>
                          <img ref={imageDesc} src={image} width={240} height={360} id="image" className="images" alt="fotografía" />
                          <img ref={imageComp} src={picture} width={240} height={360} alt="fotografía" className="images" id="picture" />
                          <br />
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              setPicture(null)
                            }}
                            className="button is-warning is-large"
                          >Tomar otra foto</button>
                        </>
                      )}
                      <div className="container mt-6">
                        <button onClick={(e) => {
                          e.preventDefault()
                          loadImages()
                        }}
                          className="button is-success is-large has-text-centered"
                        >Enviar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <button onClick={logout} className="button is-danger">Salir</button>
            </div>
          </div>
        </div>
      </div>
      {loading === true ? <div className="spinner is-fullheight is-fullwidth"><span className="loader"></span><p>Cargando...</p></div> : ""}
    </section>
  )
}

export default PeopleRegister;