import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { HashRouter } from 'react-router-dom';
import App from './App';
import "bulma/css/bulma.css" // Esta dependencia tiene vulnerabilades, revisar despues
import axios from "axios" // Se agrega casi al final

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist'

let persistor = persistStore(store);

axios.defaults.withCredentials = true // Se agrega casi al final

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>        
          <App/>        
      </PersistGate>
    </Provider>    
  </React.StrictMode>
  /*
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
  */
);