import React from "react";
import { useState } from "react";
import logo from "../logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { alert } from "../js/alert.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function PeopleRegister(props) {
  const [wNumber, setWNumber] = useState('');
  const [rfc, setRfc] = useState('');
  const navigate = useNavigate();

  const getUserById = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api-v1.seccion15.org.mx/registro-usuarios', {
        wNumber: wNumber,
        rfc: rfc
      });
      const info = response.data;
      //console.log(info);
      Swal.fire({
        title: "Genial !!",
        text: "Tienes un pre-registro, asegúrate de revisar bien todos tus datos y completa los que estén vacíos",
        icon: "success",
        footer: "No recargues/actualices la siguiente página",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#0086bf"
      })
        .then(async (response) => {
          if (response.isConfirmed) {
            //props.checkValue(true);
            props.onChange(true);
            navigate('/pre-registro-usuarios', { state: info });
          }
        })
      
    } catch (error) {
      if (error.response) {
        alert({
          title: "Inicia sesión",
          msg: error.response.data.msg,
          icon: "info"
        })
      }
    }
  }

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5">

              <form onSubmit={getUserById} className='box'>
                <div className="card-image has-text-centered">
                  <figure className="image is-128x128 is-inline-block">
                    <img src={logo} width="112" height="28" alt='Logo' />
                  </figure>
                </div>
                <div className="has-text-centered mb-2">
                  <h1 className='title is-2'>Sistema Electrónico</h1>
                  <h2>Registrarse</h2>
                </div>

                <div className="field">
                  <label htmlFor="" className="label">Número de trabajador:</label>
                  <div className="control">
                    <input
                      type="text"
                      className='input'
                      value={wNumber}
                      onChange={(e) => setWNumber(e.target.value)}
                      placeholder='*************' />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="" className="label">RFC:</label>
                  <div className="control">
                    <input
                      type="text"
                      className='input'
                      value={rfc}
                      onChange={(e) => setRfc(e.target.value)}
                      placeholder='*************' />
                  </div>
                </div>

                <div className="field mt-5">
                  <button
                    type="submit"
                    className="button is-danger is-fullwidth">
                    {/* {isLoading ? 'Loading...' : 'Login'} */}
                    {/* <Link to={'/pre-registro-usuarios'} state={data} > */}Registrarse{/* </Link> */}
                  </button>
                </div>
                <div className="field mt-5">
                  <Link to={'/'}>¿Ya tienes una cuenta?, inicia sesión</Link>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PeopleRegister;