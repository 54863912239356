import React, {useState, useEffect} from "react"; // {useState, useEffect} -> Se agregan al final
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom"; // Agregamos el useParams al final
import { alert } from "../js/alert.js";
import Swal from "sweetalert2";
import * as DOMPurify from "dompurify";

function FormEditEmpresa(){

    const [nombre_empresas, setNombre_empresas] = useState("");
    const [rfc_empresa, setRfc_empresa] = useState("");
    const [loading, setLoading] = useState(false);
    //const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        const getCompanyById = async () => {
            try {
                document.getElementById("body-edit-empresa").style.display = "none";
                setLoading(true);
                const response = await axios.get(`https://api-v1.seccion15.org.mx/empresas/${id}`);

                let empresaEdit = response.data;

                for (const key in empresaEdit) {
                    if (empresaEdit.hasOwnProperty.call(empresaEdit, key)) {                        
                        empresaEdit[key] = DOMPurify.sanitize(empresaEdit[key]);
                    }
                }

                document.getElementById("body-edit-empresa").style.display = "";
                setLoading(false);

                setNombre_empresas(response.data.nombre_empresas);
                setRfc_empresa(response.data.rfc_empresa);
            } catch (error) {
                document.getElementById("body-edit-empresa").style.display = "";
                setLoading(false);
                if(error){
                    //setMsg(error.response.data.msg);
                    alert({
                        title: "Error !",
                        msg: "Por favor póngase en contacto con el administrador",
                        icon: "error"
                    });
                }
            }
        }
        getCompanyById();
    }, [id])

    const updateCompany = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas eliminar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
          })
            .then(async(response) => {
              if (response.isConfirmed) {
                try {
                    document.getElementById("body-edit-empresa").style.display = "none";
                    setLoading(true);
                    await axios.patch(`https://api-v1.seccion15.org.mx/empresas/${id}`, {
                        nombre_empresas,
                        rfc_empresa
                    });
                    document.getElementById("body-edit-empresa").style.display = "";
                    setLoading(false);
                    alert({
                        title: "Genial !!",
                        msg: "Registro actualizado correctamente",
                        icon: "success"
                    });
                    navigate("/empresas");
                } catch (error) {
                    document.getElementById("body-edit-empresa").style.display = "";
                    setLoading(false);
                    if(error){
                        //setMsg(error.response.data.msg);
                        alert({
                            title: "Error !!",
                            msg: error.response.data.msg,
                            icon: "error",
                        });
                    }
                }
              } else if (response.isDenied) {
                document.getElementById("body-edit-empresa").style.display = "";
                setLoading(false);
              }
            })
    }

    return (
        <div className="pr-2">
            <h1 className="title">Empresas</h1>            
            <h2 className="subtitle">Editar Empresa</h2>
            <div className="card id-shadowless" id="body-edit-empresa">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={updateCompany}>
                            <div className="field">
                                <label htmlFor="" className="label">Nombre de la Empresa</label>
                                <div className="control">
                                    <input type="text" value={nombre_empresas} onChange={(e) => setNombre_empresas(e.target.value)} className="input" placeholder="Nombre de la empresa"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">RFC de la Empresa</label>
                                <div className="control">
                                    <input type="text" value={rfc_empresa} onChange={(e) => setRfc_empresa(e.target.value)} className="input" placeholder="RFC de la Empresa"/>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button className="button is-success">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
    )
}

export default FormEditEmpresa