import React, {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { alert } from "../js/alert.js";
import Swal from "sweetalert2";
import * as DOMPurify from "dompurify";
import "../css/spinner.css";

const defaultState = {
    respuesta: ""
}

function Row({ onChange, onRemove, respuesta}){
    return (
        <div className="field-group has-text-right mt-2">
            <div className="field">
                <div className="control">
                    <input 
                        value={respuesta}
                        className="input"
                        onChange={e => onChange("respuesta", e.target.value)}
                        placeholder="Escribe una respuesta"
                    />
                </div>
            </div>
            <button className="button is-danger" onClick={onRemove}>Eliminar</button>
        </div>
    );
}

function FormEditVotaciones(){
    // Creamos las variables para consultar la informacion de las empresas
    const [empresas, setEmpresas] = useState([]);
    const [loading, setLoading] = useState(false);

    // Ejecutamos la funcion para recuperar las empresas
    useEffect(() => {
        getEmpresas();
    }, []);

    // Recuperamos las empresas
    const getEmpresas = async () =>{
        const response = await axios.get("https://api-v1.seccion15.org.mx/empresas");
        setEmpresas(response.data);
    }

    const [titulo, setTitulo] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [pregunta, setPregunta] = useState("");
    const [instrucciones, setInstrucciones] = useState("");
    const [observaciones, setObservaciones] = useState("");
    const [lugar, setLugar] = useState("");
    const [fecha_inicio, setFechaInicio] = useState("");
    const [fecha_termino, setFechaTermino] = useState("");
    const [hora_inicio, setHoraInicio] = useState("");
    const [hora_termino, setHoraTermino] = useState("");
    const [id_empresa, setEmpresaId] = useState("0");
    const [activo, setActivo] = useState(0);
    const [activoActual, setActivoActual] = useState(0);
    const [estatusLabel, setEstatusLabel] = useState("");
    const [rows, setRows] = useState([defaultState]);

    //const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    const {id} = useParams();

    const handleOnChange = (index, name, value) => {
        const copyRows = [...rows];
        copyRows[index] = {
            ...copyRows[index],
            [name]: value
        };
        setRows(copyRows);
    };

    const handleOnAdd = () => {
        setRows(rows.concat(defaultState));
    };

    const handleOnRemove = index => {
        const copyRows = [...rows];
        copyRows.splice(index, 1);
        setRows(copyRows);
    };

    useEffect(() => {
        const getVotacionById = async () => {
            try {      
                setLoading(true);
                document.getElementById("body-edit-votacion").style.display = "none";          
                const response = await axios.get(`https://api-v1.seccion15.org.mx/votacion/${id}`);

                let votacionesEdit = response.data;

                const respuestasToUpdate = response.data.respuestas_votaciones[0].respuestas;
                setRows(Object.values(respuestasToUpdate).map(respuestasMap => ({ respuesta: respuestasMap.respuesta })));

                for (const key in votacionesEdit) {
                    if (votacionesEdit.hasOwnProperty.call(votacionesEdit, key)) {                        
                        votacionesEdit[key] = DOMPurify.sanitize(votacionesEdit[key]);
                    }
                }

                setTitulo(response.data.titulo);
                setDescripcion(response.data.descripcion);
                setPregunta(response.data.pregunta);
                setInstrucciones(response.data.instrucciones);
                setObservaciones(response.data.observaciones);
                setLugar(response.data.lugar);
                setFechaInicio(response.data.fecha_inicio);
                setFechaTermino(response.data.fecha_termino);
                setHoraInicio(response.data.hora_inicio);
                setHoraTermino(response.data.hora_termino);                
                setEmpresaId(response.data.id_empresa);
                setActivo(response.data.is_activate);
                setActivoActual(response.data.is_activate);

                if(response.data.is_activate == 1){
                    setEstatusLabel("Activado");
                }else{
                    setEstatusLabel("Desactivada");
                }

                document.getElementById("body-edit-votacion").style.display = "";
                setLoading(false);

            } catch (error) {
                if(error){
                    document.getElementById("body-edit-votacion").style.display = "";
                    setLoading(false);
                    //console.log(error.response.data.msg);
                    alert({
                        title: "Error !",
                        msg: "Por favor pongase en contacto con el administrador" + error,
                        icon: "error"
                    });
                }
            }
        }
        getVotacionById();
    }, [id]);

    const updateVotacion = async(e) => {
        e.preventDefault();
        Swal.fire({
            title: "Alerta!!",
            text: "¿Estás seguro que deseas editar este registro?",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "#b82b28",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#0086bf"
          })
            .then(async(response) => {
              if (response.isConfirmed) {
                try {
                    setLoading(true);
                    document.getElementById("body-edit-votacion").style.display = "none";
                    await axios.patch(`https://api-v1.seccion15.org.mx/votacion/${id}`, {
                        titulo: titulo,
                        descripcion: descripcion,
                        pregunta: pregunta,
                        instrucciones: instrucciones,
                        observaciones: observaciones,
                        lugar: lugar,
                        fecha_inicio: fecha_inicio,
                        fecha_termino: fecha_termino,
                        hora_inicio: hora_inicio,
                        hora_termino: hora_termino,
                        id_empresa: id_empresa,
                        respuestas: rows,
                        activoActual: activo,
                        activo: activo
                    });
                    document.getElementById("body-edit-votacion").style.display = "";
                    setLoading(false);
                    alert({
                        title: "Genial !!",
                        msg: "Registro actualizado correctamente",
                        icon: "success"
                    });            
                    navigate("/votaciones");
                } catch (error) {
                    document.getElementById("body-edit-votacion").style.display = "";
                    setLoading(false);
                    if(error.response){
                        //setMsg(error.response.data.msg);
                        alert({
                            title: "Error !!",
                            msg: error.response.data.msg,
                            icon: "error",
                        });
                    
                    }
                }
              } else if (response.isDenied) {
                document.getElementById("body-edit-votacion").style.display = "";
                setLoading(false);
              }
            })
    }

    return (
        <div className="pr-2">
            <h1 className="title">Votaciones</h1>
            <h2 className="subtitle">Editar datos de votación</h2>
            <div className="card id-shadowless" id="body-edit-votacion">
                <div className="card-content">
                    <div className="content">
                        <form onSubmit={updateVotacion}>
                            {/* <p className="has-text-centered">{msg}</p> */}
                            <div className="field">
                                <label htmlFor="" className="label">Titulo de la votación</label>
                                <div className="control">
                                    <input type="text"
                                        className="input"
                                        value={titulo} onChange={(e) => setTitulo(e.target.value)}
                                        placeholder="Titulo de la votación"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Descripción de la votación</label>
                                <div className="control">
                                    <input type="text"
                                        className="input"
                                        value={descripcion} onChange={(e) => setDescripcion(e.target.value)}
                                        placeholder="Descripción de la votación"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Pregunta de la votación</label>
                                <div>
                                    <input type="text"
                                        className="input"
                                        value={pregunta} onChange={(e) => setPregunta(e.target.value)}
                                        placeholder="¿Que cuestion estara a votación?"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Instrucciónes para la votación</label>
                                <div>
                                    <input type="text"
                                        className="input"
                                        value={instrucciones} onChange={(e) => setInstrucciones(e.target.value)}
                                        placeholder="Instrucciónes para la votación"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Observaciónes</label>
                                <div>
                                    <input type="text"
                                        className="input"
                                        value={observaciones} onChange={(e) => setObservaciones(e.target.value)}
                                        placeholder="Escribe alguna observación para esta votacion"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Lugar de la votación</label>
                                <div>
                                    <input type="text"
                                        className="input"
                                        value={lugar} onChange={(e) => setLugar(e.target.value)}
                                        placeholder="Donde tendra lugar la votación"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Fecha de inicio de la votación</label>
                                <div>
                                    <input type="date"
                                    value={fecha_inicio} onChange={(e) => setFechaInicio(e.target.value)}
                                    className="input"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Fecha de termino de la votación</label>
                                <div>
                                    <input type="date"
                                    value={fecha_termino} onChange={(e) => setFechaTermino(e.target.value)}
                                    className="input"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Hora de inicio(24 hrs)</label>
                                <div>
                                    <input type="time"
                                    value={hora_inicio} onChange={(e) => setHoraInicio(e.target.value)}
                                    className="input"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Hora de termino(24 hrs)</label>
                                <div>
                                    <input type="time"
                                    value={hora_termino} onChange={(e) => setHoraTermino(e.target.value)}
                                    className="input"/>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Seleccióna una empresa</label>
                                <div className="select">
                                    <select value={id_empresa} onChange={(e) => setEmpresaId(e.target.value)}>
                                        <option value={"0"}>Selecciona una empresa</option>
                                        {empresas.map((empresa, index) => (
                                            <option key={index + 1} value={empresa.id}>{empresa.nombre_empresas}</option>
                                        ))}                                        
                                    </select>
                                </div>
                            </div>
                            <div className="field">
                                <label htmlFor="" className="label">Estatus de la votacion: <span className='has-text-success'>{estatusLabel}</span></label>
                                <div className="select">
                                    <select onChange={(e) => setActivo(e.target.value)}>
                                        <option value={"-1"}>Cambiar estado</option>
                                        <option value={0}>Desactivada</option>
                                        <option value={1}>Activada</option>
                                    </select>
                                </div>
                            </div>
                            <div className="field has-text-centered">
                                <label htmlFor="" className="label">Agrega nuevas respuestas para esta votación</label>
                                {rows.map((row, index) => (
                                    <Row
                                        {...row}
                                        onChange={(name, value) => handleOnChange(index, name, value)}
                                        onRemove={() => handleOnRemove(index)}
                                        key={index}
                                    />
                                ))}
                                <input 
                                    type="button"
                                    value='Agregar'
                                    onClick={handleOnAdd}
                                    className="button is-link"
                                 />
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
        </div>
    )
}

export default FormEditVotaciones