import {BrowserRouter, Routes, Route} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Registro from "./components/Registro";
import RegistroVotosUsuario from "./pages/RegistroVotos";
import Empresas from "./pages/Companies";
import AddEmpresas from "./pages/AddEmpresas";
import EditEmpresa from "./pages/EditEmpresa";
import Usuarios from "./pages/Users";
import Register from "./pages/Register";
import EditUser from "./pages/EditUser";
import PreRegistros from "./pages/PreRegistro";
import UploadFileCsv from "./pages/UploadCsv";
import AddPreRegistro from "./pages/AddPreRegistro";
import EditPreRegistro from "./pages/EditPreRegistro";
import VotacionesList from "./pages/Votaciones";
import ConsultaVoto from "./pages/ConsultaVoto";
import AddVotaciones from "./pages/AddVotaciones";
import EditVotaciones from "./pages/EditVotaciones";
import ResultVotaciones from "./pages/ResultVotaciones";
import PeopleRegister from "./components/PeopleRegister";
import PreRegistroUsers from "./components/PreRegistroUsers"
import NotFound from "./components/404";
import BoletaVotacion from "./pages/Boleta";
import RecoverPassword from "./pages/RecoverPassword";
import ResetPassword from "./pages/ResetPassword"
import { useState } from "react";
import FaceRecognition from "./components/FaceRecognition";
import CompareFace from "./components/CompareFace";
import { HashRouter } from "react-router-dom";

function App() {
  //Este useState es para que no te deje acceder a la ruta de pre-registro a menos que hayas pasado previamente por el
  //formulario de registro-usuarios
  const [check, setCheck] = useState(false);
  function checkValue(value){
    setCheck(value);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/Registro" element={<Registro/>}/>
          <Route path="/registro-votos" element={<RegistroVotosUsuario/>}/>
          <Route path="/recuperar-cuenta" element={<RecoverPassword/>}/>
          <Route path="/resetPassword/:id/:token" element={<ResetPassword/>}/>
          <Route path="/dashboard" element={<Dashboard />}/>          
          <Route path="/users" element={<Usuarios />}/>
          <Route path="/users/add" element={<Register />}/>
          <Route path="/users/edit/:id" element={<EditUser />}/>
          <Route path="/empresas" element={<Empresas />}/>
          <Route path="/empresas/add" element={<AddEmpresas />}/>
          <Route path="/empresas/edit/:id" element={<EditEmpresa/>}/>
          <Route path="/pre-registro" element={<PreRegistros/>}/>
          <Route path="/pre-registro" element={<PreRegistros/>}/>
          <Route path="/uploadCsv" element={<UploadFileCsv/>}/>
          <Route path="/pre-registro/add" element={<AddPreRegistro/>}/>
          <Route path="/pre-registro/edit/:id" element={<EditPreRegistro/>}/>
          <Route path="/votaciones" element={<VotacionesList/>}/>
          <Route path="/votaciones/add" element={<AddVotaciones/>}/>
          <Route path="/votaciones/edit/:id" element={<EditVotaciones/>}/>
          <Route path="/votaciones/resultados/:id" element={<ResultVotaciones/>}/>
          <Route path="/votacion/:id" element={<BoletaVotacion/>}/>
          <Route path="/reconocimiento" element={<FaceRecognition/>}/>
          <Route path="/reconocimiento-facial" element={<CompareFace/>}/>
          <Route path="/consulta/:id" element={<ConsultaVoto/>}/>
          {/* Acá se le pasan los parámetros del useState de setCheck para actualizar la variable que te deja acceder a la siguiente ruta*/}
          <Route path="/registro-usuarios" element={<PeopleRegister check={check} onChange={checkValue} />}/>

          {/* { check ? <Route path="/pre-registro-usuarios" element={<PreRegistroUsers />}/> : <Route path="/" element={<Login/>}/> } */}
          {/* <Route path="/pre-registro-usuarios" element={<PreRegistroUsers/>}/> */}
          <Route path="/pre-registro-usuarios" element={ check ? (<PreRegistroUsers/>) : (<PeopleRegister/>)}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
