import React, { useState} from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { alert } from "../js/alert";
import ReactPasswordChecklist from "react-password-checklist";

function PreRegistroUsers(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const { nombre, apellido_paterno, apellido_materno, area, curp, rfc, fecha_ingreso, num_empleado, posicion, /* rfc_empresa,  */id, nombre_empresas, role } = location.state;
  const [num_empleadoPre, setNumEmpleado] = useState(num_empleado);
  const [nombrePre, setNombre] = useState(nombre);
  const [apellido_paternoPre, setApellidoP] = useState(apellido_paterno);
  const [apellido_maternoPre, setApellidoM] = useState(apellido_materno);
  const [rfcPre, setRFC] = useState(rfc);
  const [curpPre, setCURP] = useState(curp);
  const [fecha_ingresoPre, setFechaIngreso] = useState(fecha_ingreso);
  const [posicionPre, setPosicion] = useState(posicion);
  const [areaPre, setArea] = useState(area);
  //const [rfc_empresaPre, setRfcEmpresa] = useState(rfc_empresa);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  const [isValid, setPasswordValid] = useState(false);
  function checkValidPassword(value){    
    setPasswordValid(value)
  }

  //const [role, setRole] = useState('user');  

  const savePreRegistro = async (e) => {
    e.preventDefault();

    document.getElementById("body-pre-registro").style.display = "none";
    setLoading(true);
    
    if(!isValid){
      document.getElementById("body-pre-registro").style.display = "";
      setLoading(false);
      Swal.fire({
        title: "Contrseña invalida",
        text: "Tu contraseña no cumple con los estandares necesarios",
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "Cancelar",
        denyButtonColor: "#b82b28",
      });
      return;
    }

    document.getElementById("body-pre-registro").style.display = "";
    setLoading(false);

    Swal.fire({
      title: "Alerta!!",
      text: `Recuerda que se enviará un correo a "${email}", revisa que esté correcto`,
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "Cancelar",
      denyButtonColor: "#b82b28",
      confirmButtonText: "Si, mis datos están correctos",
      confirmButtonColor: "#0086bf"
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          try {
            document.getElementById("body-pre-registro").style.display = "none";
            setLoading(true);
            await axios.post("https://api-v1.seccion15.org.mx/registro-completo", {
              num_empleadoPre,
              nombrePre,
              apellido_paternoPre,
              apellido_maternoPre,
              rfcPre,
              curpPre,
              fecha_ingresoPre,
              posicionPre,
              areaPre,
              email,
              password,
              confPassword,
              id,
              role
            });
            document.getElementById("body-pre-registro").style.display = "";
            setLoading(false);
            alert({
              title: "Genial !!",
              msg: "Usuario registrado correctamente, ahora puedes iniciar sesión",
              icon: "success"
            });
            navigate("/");
          } catch (error) {
            document.getElementById("body-pre-registro").style.display = "";
            setLoading(false);
            if (error.response) {
              /* setMsg(error.response.data.msg); */
              alert({
                title: "Error !!",
                msg: error.response.data.msg,
                icon: "error"
              });
            }
          }
        } else if (response.isDenied) {
          document.getElementById("body-pre-registro").style.display = "";
          setLoading(false);
        }
      })
  }

  return (
    <section className="hero has-background-grey-light is-fullheight is-fullwidth">
      <div className="hero-body is-8" id="body-pre-registro">

        <div className="container">
          <div className="pr-2">
            <h1 className="title has-text-centered">Pre Registro</h1>
            <div className="card id-shadowless">
              <div className="card-content">
                <div className="content">
                  {/* <form onSubmit={savePreRegistro}> */}
                  <form onSubmit={savePreRegistro}>
                    {/* <p className="has-text-centered">{msg}</p> */}
                    <div className="field">
                      <label htmlFor="" className="label">Número de empleado</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={num_empleadoPre} onChange={(e) => setNumEmpleado(e.target.value)}
                          placeholder="Número de empleado" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Nombre</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={nombrePre} onChange={(e) => setNombre(e.target.value)}
                          placeholder="Nombre" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Apellido paterno</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={apellido_paternoPre} onChange={(e) => setApellidoP(e.target.value)}
                          placeholder="Apellido paterno" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Apellido materno</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={apellido_maternoPre} onChange={(e) => setApellidoM(e.target.value)}
                          placeholder="Apellido materno" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">RFC</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={rfcPre} onChange={(e) => setRFC(e.target.value)}
                          placeholder="XXXXXXXXXXXXX" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">CURP</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={curpPre} onChange={(e) => setCURP(e.target.value)}
                          placeholder="XXXXXXXXXXXXXXXXXX" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Fecha de ingreso</label>
                      <div className="control">
                        <input type="date"
                          value={fecha_ingresoPre} onChange={(e) => setFechaIngreso(e.target.value)}
                          className="input" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Posición</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={posicionPre} onChange={(e) => setPosicion(e.target.value)}
                          placeholder="Posición" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Area</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          value={areaPre} onChange={(e) => setArea(e.target.value)}
                          placeholder="Area" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Nombre de la Empresa</label>
                      <div className="control">
                        <input type="text"
                          className="input"
                          disabled
                          value={nombre_empresas} />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Email</label>
                      <div className="control">
                        <input type="email"
                          className="input"
                          value={email} onChange={(e) => setEmail(e.target.value)}
                          placeholder="correo@email.com" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Contraseña</label>
                      <div className="control">
                        <input type="password"
                          className="input"
                          value={password} onChange={(e) => setPassword(e.target.value)}
                          placeholder="Escribe tu contraseña" />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="" className="label">Confirma tu contraseña</label>
                      <div className="control">
                        <input type="password"
                          className="input"
                          value={confPassword} onChange={(e) => setConfPassword(e.target.value)}
                          placeholder="Escribe nuevamente tu contraseña" />
                      </div>
                    </div>

                    <ReactPasswordChecklist
                      rules={["minLength","specialChar","number","capital","match"]}
                      minLength={8}
                      value={password}
                      valueAgain={confPassword}
                      onChange={(isValid) => {checkValidPassword(isValid)}}
                      messages={{
                        minLength: "La contraseña tiene más de 8 caracteres.",
                        specialChar: "La contraseña tiene caracteres especiales.",
                        number: "La contraseña tiene un número.",
                        capital: "La contraseña tiene una letra mayúscula.",
                        match: "Las contraseñas coinciden.",
                      }}
                    />
                    
                    <div className="field">
                      <div className="control">
                        <button type="submit" className="button is-success">Registrar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading === true ? <div className="spinner"><span className="loader"></span><p>Cargando...</p></div> : ""}
    </section>
  )
}

export default PreRegistroUsers;